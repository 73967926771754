

export const Input = {
    baseStyle: {
    },
    variants : {
        themeInput : {
            field : {
                backgroundColor : "rgba(255,255,255,.1)",
                border: "solid 1px transparent",
                _focus : {
                    borderColor : "#34543f"
                }
            }
        }
    }
}